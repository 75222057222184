import {useContext, useEffect, useState} from "react";

//as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import CheckIcon from '@mui/icons-material/Check';
import ToggleButton from '@mui/material/ToggleButton';
import ECContext from "../../context/deal-assignments/ECContext";
import Spinner from "../layout/Spinner";


export default function TransferPhotoList() {

   const { photos, selectedPhotos, showBuildDateDialog, dialogLoading, dispatch } = useContext(ECContext)

   const [selected, setSelected] = useState(false);
   const [selectedStates, setSelectedStates] = useState([]);

   const [photoData, setPhotoData] = useState([]);

   const [inputValues, setInputValues] = useState({})
   const handleChange = (name, value) => {
      setInputValues({
         ...inputValues,
         [name]: value
      })

      // console.log("~~~ setting up selected Photos from List:")
      // console.log({
      //    ...inputValues,
      //    [name]: value
      // })

      dispatch({ type: 'SET_SELECTED_PHOTOS',
         payload: {
            ...inputValues,
            [name]: value
         }
      })

   }



   useEffect(() => {


      // if(dialogLoading)
      //    return

      // console.log("~~~~ incoming photo was Input data")
      // console.log(photos)
      //
      // console.log(`photos instance of Array: ${photos instanceof Array}`)
      // console.log(`photos length: ${photos.length}`)
      //
      // let test = []
      // test = Array.from(photos)
      // console.log(`test instance of Array: ${test instanceof Array}`)
      // console.log(test)

      let itemData = []
      photos.forEach((photoItem, index) => {

         //console.log(`${index}: photoItem: ${photoItem}`)

         const tmp = {
            //id: index,
            id: photoItem.fileId,
            img: photoItem.url,
            title: photoItem.fileName
         }

         itemData.push(tmp)

      })

      // console.log("~~~~ ready to use array (photoData):")
      // console.log(itemData)

      // let tempData = photos.map((elem, index) => {
      //
      //    return {id: index, img: elem.url, title: elem.fileName}
      // })

      //if(itemData.length > 0)
      setPhotoData(itemData)


      dispatch({ type: 'SET_SELECTED_PHOTOS',
         payload: {}
      })
      setInputValues({})



   }, [photos]);



   if (!dialogLoading) {
      return (

         <>
            <ImageList
               sx={{
                  width: 500,
                  height: 450,
                  // Promote the list into its own layer in Chrome. This costs memory, but helps keeping high FPS.
                  transform: 'translateZ(0)',
               }}
               rowHeight={200}
               gap={1}
            >
               {photoData.map((item) => {
                  const cols = item.featured ? 2 : 1;
                  const rows = item.featured ? 2 : 1;


                  return (
                     <ImageListItem key={item.img} cols={cols} rows={rows}>
                        <img
                           {...srcset(item.img, 250, 200, rows, cols)}
                           id={item.id}
                           //alt={item.title}
                           //loading="lazy"
                           //crossOrigin="anonymous"
                        />
                        <ImageListItemBar
                           sx={{
                              background:
                                 'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                                 'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
                           }}
                           title={item.title}
                           position="top"
                           actionIcon={
                              <ToggleButton
                                 /*
                                                            sx={{ color: 'white' }}
                                                            aria-label={`star ${item.title}`}
                                 */

                                 color="warning"

                                 value="check"
                                 selected={inputValues[item.id]}
                                 onChange={() => {
                                    handleChange(item.id, !inputValues[item.id])
                                 }}
                              >
                                 {/*<StarBorderIcon />*/}
                                 <CheckIcon />
                              </ToggleButton>
                           }
                           actionPosition="left"
                        />
                     </ImageListItem>
                  );
               })}
            </ImageList>

         </>
      );
   }
   else {
      return <Spinner />
   }

}



function srcset(image, width, height, rows = 1, cols = 1) {
   return {
      src: `${image}?w=${width * cols}&h=${height * rows}&fit=crop&auto=format`,
      srcSet: `${image}?w=${width * cols}&h=${
         height * rows
      }&fit=crop&auto=format&dpr=2 2x`,
   };
}



// const itemData = [
//    {
//       id: 1,
//       img: '/img/1.jpg',
//       title: '',
//       author: '',
//    },
//    {
//       id: 2,
//       img: '/img/2.jpg',
//       title: '',
//       author: '',
//    },
//    {
//       id: 3,
//       img: '/img/3.jpg',
//       title: '',
//       author: '',
//    },
//    {
//       id: 4,
//       img: '/img/4.jpg',
//       title: '',
//       author: '',
//    },
//    {
//       id: 5,
//       img: '/img/5.jpg',
//       title: '',
//       author: '',
//    },
//    {
//       id: 6,
//       img: '/img/6.jpg',
//       title: '',
//       author: '',
//    },
//    {
//       id: 7,
//       img: '/img/7.jpg',
//       title: '',
//       author: '',
//    },
//    {
//       id: 8,
//       img: '/img/8.jpg',
//       title: '',
//       author: '',
//    },
//    {
//       id: 9,
//       img: '/img/9.jpg',
//       title: '',
//       author: '',
//    },
//    {
//       id: 10,
//       img: '/img/10.jpg',
//       title: '',
//       author: '',
//    },
//    {
//       id: 11,
//       img: '/img/11.jpg',
//       title: '',
//       author: '',
//    },

/*   {
      img: 'https://images.unsplash.com/photo-1551782450-a2132b4ba21d',
      title: 'Burger',
      author: '@rollelflex_graphy726',
   },
   {
      img: 'https://images.unsplash.com/photo-1522770179533-24471fcdba45',
      title: 'Camera',
      author: '@helloimnik',
   },
   {
      img: 'https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c',
      title: 'Coffee',
      author: '@nolanissac',
   },
   {
      img: 'https://images.unsplash.com/photo-1533827432537-70133748f5c8',
      title: 'Hats',
      author: '@hjrc33',
   },
   {
      img: 'https://images.unsplash.com/photo-1558642452-9d2a7deb7f62',
      title: 'Honey',
      author: '@arwinneil',
//      featured: true,
   },
   {
      img: 'https://images.unsplash.com/photo-1516802273409-68526ee1bdd6',
      title: 'Basketball',
      author: '@tjdragotta',
   },
   {
      img: 'https://images.unsplash.com/photo-1518756131217-31eb79b20e8f',
      title: 'Fern',
      author: '@katie_wasserman',
   },
   {
      img: 'https://images.unsplash.com/photo-1597645587822-e99fa5d45d25',
      title: 'Mushrooms',
      author: '@silverdalex',
   },
   {
      img: 'https://images.unsplash.com/photo-1567306301408-9b74779a11af',
      title: 'Tomato basil',
      author: '@shelleypauls',
   },
   {
      img: 'https://images.unsplash.com/photo-1471357674240-e1a485acb3e1',
      title: 'Sea star',
      author: '@peterlaster',
   },
   {
      img: 'https://images.unsplash.com/photo-1589118949245-7d38baf380d6',
      title: 'Bike',
      author: '@southside_customs',
   },*/
//];
